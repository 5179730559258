/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

import { Theme, withTheme } from '../../styled';
import Modal from '../Modal';

import Button from './Button';
import { ClickableProps } from './Clickable';

// Fix aria modal missing prop
declare module 'react-aria-modal' {
	interface AriaModalProps {
		scrollDisabled?: boolean;
	}
}

interface Props extends ClickableProps {
	theme: Theme;
	icon?: React.ReactNode;
	title?: string;
	text?: string;
}

export const ConfirmButton: React.FunctionComponent<Props> = ({
	icon,
	theme,
	onClick,
	title,
	text,
	...props
}) => {
	const [confirm, setConfirm] = React.useState(false);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		// Show confirm dialog
		e.stopPropagation();
		setConfirm(true);
	};
	const handleOK = () => {
		setConfirm(false);
		if (onClick) {
			onClick(null as any);
		}
	};
	const handleCancel = () => {
		setConfirm(false);
	};

	return (
		<React.Fragment>
			<Button
				icon={icon}
				css={css`
					padding: 1ex;
				`}
				onClick={handleClick}
				{...props}
			/>
			{confirm && (
				<Modal
					cancel
					title={title}
					text={text}
					onClickOK={handleOK}
					onClickCancel={handleCancel}
				/>
			)}
		</React.Fragment>
	);
};

export default withTheme(ConfirmButton);
