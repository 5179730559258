/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import styled from '../../styled';

const Wrap = styled('div')<DataValueStyleProps>`
	font-weight: ${(props) => props.theme.font.styles.value.fontWeight};
	font-size: ${(props) => props.theme.font.styles.value.fontSize};
	letter-spacing: ${(props) => props.theme.font.styles.value.letterSpacing};
	padding-right: 0.4em;
	transition: all 0.4s ease;
	${(props) =>
		props.wrapOverflow
			? `
		overflow-wrap: anywhere;
	`
			: null}
	${(props) =>
		props.isFake
			? `
			filter: blur(.2em);`
			: null}
	${(props) =>
		props.preserveWhitespace &&
		`
		white-space: pre;
	`}
`;

interface DataValueStyleProps {
	isFake?: boolean;
	wrapOverflow?: boolean;
	preserveWhitespace?: boolean;
}

export interface DataValueProps extends DataValueStyleProps {
	value: any;
}

const DataValue: React.FunctionComponent<DataValueProps> = ({ value, ...props }) => <Wrap {...props}>{value}</Wrap>;

export default React.memo(DataValue);
