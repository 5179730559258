/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

export interface IconProps extends React.SVGAttributes<SVGElement> {
	d?: string;
}

export interface IconFunc extends React.FunctionComponent<IconProps> {}

const Icon: IconFunc = ({ children, d, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		css={css`
			width: 1.5em;
			height: 1.5em;
			display: block;
		`}
		{...props}
	>
		<path d={d} />
		{children}
	</svg>
);

export default Icon;
