/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import React from 'react';

import { Theme } from '../../styled';

import Clickable, { ClickableProps } from './Clickable';

export interface ButtonProps extends ClickableProps {
	icon?: React.ReactNode;
	isHighlighted?: boolean;
	hasBackground?: boolean;
	noBorder?: boolean;
	iconCss?: any;
	childrenCss?: any;
	wrapCss?: SerializedStyles;
}

const Button: React.FunctionComponent<ButtonProps> = ({
	children,
	icon,
	wrapCss,
	isHighlighted,
	hasBackground,
	noBorder,
	iconCss,
	childrenCss,
	...props
}) => {
	const style = (theme: Theme) => [
		css`
			padding: 1ex 1em;

			&:not(:active):not(:hover) {
				color: ${hasBackground && isHighlighted
					? theme.color.primaryContrast
					: 'inherit'};
			}

			&:after {
				height: ${noBorder ? 0 : isHighlighted ? 2 : 1}px;
			}

			&:before {
				border-radius: 1px;
				background: ${hasBackground
					? isHighlighted
						? theme.color.primary
						: theme.color.ultraLightgrey
					: undefined};
			}

			${wrapCss}
		`
	];

	return (
		<Clickable css={style} {...props}>
			{icon ? <div css={iconCss}>{icon}</div> : null}
			{children ? (
				<div
					css={css`
						margin-left: ${icon ? 0.4 : 0}em;
						${childrenCss};
					`}
				>
					{children}
				</div>
			) : null}
		</Clickable>
	);
};

export default Button;
