/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

const onExit = (node: HTMLElement) => {
	// let's position the element at visually
	// the same position but in different dom scope
	if (!node.offsetParent) {
		return;
	}
	const parentRect = node.offsetParent.getBoundingClientRect();
	const rect = node.getBoundingClientRect();
	node.style.position = 'absolute';
	node.style.left = rect.left - parentRect.left + 'px';
	node.style.top = '0px';
	node.style.width = rect.width + 'px';
	node.style.height = rect.height + 'px';
};

const onExited = (node: HTMLElement) => {
	node.style.display = '';
	node.style.position = '';
	node.style.left = '';
	node.style.top = '';
	node.style.width = '';
	node.style.height = '';
	node.style.opacity = '';
	node.style.background = '';
};

interface PageTransitionProps {
	in: boolean;
	timeout?: number;
	transitionName?: string;
	transitionKey?: string;
}

export const PageTransition: React.FunctionComponent<PageTransitionProps> = ({
	timeout,
	transitionName,
	children,
	transitionKey,
	...props
}) => {
	const _timeout = timeout || 150;
	return (
		<CSSTransition
			{...props}
			timeout={_timeout}
			classNames={transitionName || 'zoom'}
			appear
			mountOnEnter
			unmountOnExit
			onExit={onExit}
			onExited={onExited}
			key={transitionKey || 1}
		>
			<div
				css={(theme) => css`
					position: relative;
					opacity: 0;
					${transitionName !== 'fade' &&
					css`
						transform: scale(1.2);
						transform-origin: center top;
					`}
					transition: all ${_timeout}ms ease-out;

					&.zoom-appear,
					&.zoom-enter {
						transform: scale(1.2);
						z-index: 1;
						opacity: 0;
						transition-delay: ${_timeout}ms;
					}

					&.zoom-appear-active,
					&.zoom-enter-active {
						transform: scale(1);
						opacity: 1;
					}

					&.zoom-enter-done {
						transform: none;
						opacity: 1;
					}

					&.zoom-exit {
						transform: scale(1);
						opacity: 0;
					}

					&.zoom-exit-active {
						transform: scale(0.8);
					}

					&.slide-appear,
					&.slide-enter {
						transform: translate(100%, 0);
						display: block;
						z-index: 1;
					}

					&.slide-appear-active,
					&.slide-enter-active {
						transform: translate(0, 0);
						transition: all ${_timeout}ms ease-out;
						display: block;
						opacity: 1;
						z-index: 1;
					}

					&.slide-enter-done {
						transform: translate(0, 0);
						display: block;
						opacity: 1;
						z-index: 1;
					}

					&.slide-exit {
						transform: translate(0, 0);
						display: block;
						opacity: 1;
						z-index: 1;
					}

					&.fade-exit-active {
						opacity: 0;
					}

					&.fade-appear,
					&.fade-enter {
					}

					&.fade-appear-active,
					&.fade-enter-active {
						opacity: 1;
					}

					&.fade-enter-done {
						opacity: 1;
					}

					&.fade-exit {
						opacity: 1;
					}

					&.fade-exit-active {
						opacity: 0;
					}
				`}
			>
				{children}
			</div>
		</CSSTransition>
	);
};

export default PageTransition;
