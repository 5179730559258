/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

import styled from '../../styled';
import Clickable from '../form/Clickable';

interface LabelProps {
	isActive: boolean;
}

const Label = styled('div')`
	font-size: ${props => props.theme.tabset.tab.fontSize || null};
	letter-spacing: 0.1em;
	position: relative;
`;

const SpacerLabel = styled('span')`
	font-weight: ${props => props.theme.font.weight.bold};
	opacity: 0;
	pointer-events: none;
`;

const VisibleLabel = styled('span')<LabelProps>`
	font-weight: ${props =>
		props.isActive
			? props.theme.font.weight.bold
			: props.theme.font.weight.thin};
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	transition: font 0.4s ease;
`;

const Count = styled('div')`
	padding-left: 1ex;
	font-size: ${props => props.theme.tabset.tab.fontSize || null};
	font-weight: ${props => props.theme.font.weight.thin || null};
`;

interface TabProps extends React.ButtonHTMLAttributes<any> {
	label: string;
	count?: number;
	isActive?: boolean;
	isDisabled?: boolean;
}

const Tab: React.FunctionComponent<TabProps> = ({
	label,
	count,
	isActive,
	children,
	...props
}) => (
	<Clickable
		css={css`
			padding-left: 1.4em;
			padding-right: 1.4em;
		`}
		isActive={!!isActive}
		{...props}
	>
		<Label>
			<SpacerLabel>{label}</SpacerLabel>
			<VisibleLabel isActive={!!isActive}>{label}</VisibleLabel>
		</Label>
		{typeof count !== 'undefined' ? <Count>({count})</Count> : null}
	</Clickable>
);

export default Tab;
