import React from 'react';

import styled from '../../styled';
import { ButtonReset } from '../utils';

export interface ClickableProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
	noBorder?: boolean;
}

const Clickable = styled('button')<ClickableProps>`
	${ButtonReset};
	position: relative;
	z-index: 9;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	transition: all 0.2s ease-out;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: transparent;
		pointer-events: none;
		transition: all 0.9s ease-out;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 1px;
		background-color: ${props =>
			props.isActive ? props.theme.color.primary : props.theme.color.grey};
		transition: all 0.4s ease-out;
	}

	&:hover {
		fill: ${props => props.theme.color.primary};

		&:before {
			background-color: ${props => props.theme.color.lightgrey};
		}

		&:after {
			background-color: ${props => props.theme.color.primary};
		}
	}

	&:active {
		opacity: 0.5;

		&:before {
			transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
			background-color: ${props => props.theme.color.lightgrey};
		}

		&:after {
			background-color: ${props => props.theme.color.primary};
		}
	}

	&::-moz-focus-inner {
		border: 0;
	}

	&:focus {
		outline: none;
		fill: ${props => props.theme.color.primary};

		&:before {
			transition: all 0.2s ease-out;
			background-color: ${props => props.theme.color.lightgrey};
		}

		&:after {
			background-color: ${props => props.theme.color.secondary};
		}
	}

	&:disabled {
		pointer-events: none;
		cursor: default;
		opacity: 1;
		color: ${props => props.theme.color.grey};
		fill: ${props => props.theme.color.grey};
		background-color: transparent;

		&:after {
			background-color: ${props => props.theme.color.lightgrey};
		}
	}
`;

export default Clickable;
