/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { theme } from '../../styled';
import LoadingBars from '../icons/LoadingBars';

const loaderClass = css`
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 1ex;
	background-color: ${theme.color.background};
	z-index: 2000;
	& > svg {
		width: 1em;
		height: auto;
		& g {
			fill: ${theme.color.foreground};
		}
	}
`;

const errorClass = css`
	${loaderClass};
	z-index: 2001;
	color: ${theme.color.error};
	border: 1px solid ${theme.color.error};
	& > span {
		font-weight: ${theme.font.weight.medium};
		cursor: pointer;
	}
`;

const LoadingComp: React.FunctionComponent<LoadingComponentProps> = props => {
	if (props.error) {
		return (
			<div css={errorClass}>
				Error loading component! <span onClick={props.retry}>Retry</span>
			</div>
		);
	} else if (props.pastDelay) {
		return (
			<div css={loaderClass}>
				<LoadingBars />
			</div>
		);
	}
	return null;
};

export default LoadingComp;
