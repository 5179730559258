/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Icon, { IconProps } from './index';

const Save: React.FunctionComponent<IconProps> = props => (
	<Icon
		{...props}
		d="M17 3H3v18h18V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"
	/>
);

export default Save;
