import { css } from '@emotion/core';
import styled, { CreateStyled } from '@emotion/styled';
import { withTheme as oldWithTheme } from 'emotion-theming';
import { ComponentClass, SFC } from 'react';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export default styled as CreateStyled<Theme>;
export const withTheme: <Props>(
	component:
		| ComponentClass<Props>
		| SFC<Props>
		| ComponentClass<Props & { theme: Theme }>
		| SFC<Props & { theme: Theme }>
) => ComponentClass<Omit<Props, 'theme'>> = oldWithTheme as any;

export interface ThemeColors {
	primary: string;
	primaryContrast: string;
	primaryLight: string;
	secondary: string;
	foreground: string;
	background: string;
	grey: string;
	lightgrey: string;
	lightergrey: string;
	ultraLightgrey: string;
	darkgrey: string;
	error: string;
	errorContrast: string;
	warning: string;
	warningContrast: string;
	message: string;
	messageContrast: string;
}

export interface ThemeFonts {
	size: {
		label: string;
		main: string;
		meta: string;
		value: string;
	};
	weight: {
		medium: number;
		bold: number;
		thin: number;
		ultraThin: number;
		black: number;
		regular: number;
	};
	styles: {
		bold: {
			fontWeight: number;
			letterSpacing: string;
		};
		inputField: {
			fontWeight: number;
			fontSize: string;
			letterSpacing: string;
		};
		meta: {
			fontWeight: number;
			textTransform: string;
			fontSize: string;
			letterSpacing: string;
		};
		value: {
			fontWeight: number;
			fontSize: string;
			letterSpacing: string;
		};
	};
}

export interface Theme {
	color: ThemeColors;
	app: {
		header: {
			heightS: string;
			heightL: string;
			color: string;
			backgroundColor: string;
			transitionDuration: string;
		};
		footer: {
			height: string;
			backgroundColor: string;
		};
	};
	dimensions: {
		contentMaxWidth: string;
		sidePadding: string;
	};
	font: ThemeFonts;
	tabset: {
		height: string;
		tab: {
			fontSize: string;
		};
	};
	form: {
		input: {
			backgroundColor: string;
			backgroundColorFocused: string;
			backgroundColorDisabled: string;
			color: string;
			colorFocused: string;
			colorDisabled: string;
			padding: string;
			borderRadius: string;
			fontSize: string;
		};
	};
}

const colors: ThemeColors = {
	foreground: '#0A1317',
	background: '#FFFFFF',
	ultraLightgrey: '#F6F6F6',
	lightgrey: '#E3E3E3',
	lightergrey: '#DADADA',
	grey: '#BCBCBC',
	darkgrey: '#7B7B7B',
	primary: '#E0364B',
	primaryContrast: '#FFFFFF',
	primaryLight: '#F3C0D8',
	secondary: '#8C0F1F',
	error: '#D0021B',
	errorContrast: '#FFFFFF',
	warning: '#e28305',
	warningContrast: '#462903',
	message: '#417505',
	messageContrast: '#FFFFFF'
};

const font: ThemeFonts = {
	size: {
		label: '75%',
		main: '87.5%',
		meta: '70%',
		value: '120%'
	},
	weight: {
		black: 900,
		bold: 700,
		medium: 600,
		regular: 500,
		thin: 300,
		ultraThin: 100
	}
} as any;

font.styles = {
	bold: {
		fontWeight: font.weight.bold,
		letterSpacing: '.05em'
	},
	inputField: {
		fontWeight: font.weight.medium,
		fontSize: font.size.main,
		letterSpacing: '.05em'
	},
	meta: {
		fontWeight: font.weight.thin,
		textTransform: 'uppercase',
		fontSize: font.size.meta,
		letterSpacing: '.1em'
	},
	value: {
		fontWeight: font.weight.thin,
		fontSize: font.size.value,
		letterSpacing: '.1em'
	}
};

export const theme: Theme = {
	color: colors,
	font,
	dimensions: {
		contentMaxWidth: '70em',
		sidePadding: '0.4em'
	},
	app: {
		header: {
			heightS: 22 / 16 + 'em',
			heightL: 87 / 16 + 'em',
			backgroundColor: colors.ultraLightgrey,
			color: colors.primary,
			transitionDuration: '900ms'
		},
		footer: {
			height: '2.6em',
			backgroundColor: colors.ultraLightgrey
		}
	},
	tabset: {
		height: '2.7em',
		tab: {
			fontSize: font.size.main
		}
	},
	form: {
		input: {
			backgroundColor: colors.ultraLightgrey,
			backgroundColorFocused: colors.background,
			backgroundColorDisabled: colors.ultraLightgrey,
			color: colors.foreground,
			colorFocused: colors.foreground,
			colorDisabled: colors.grey,
			padding: '1ex',
			borderRadius: '1px',
			fontSize: font.size.value
		}
	}
};

export const GLOBAL_STYLES = css`
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-16UltThIt.otf');
		font-style: italic;
		font-weight: 100;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-15UltTh.otf');
		font-style: normal;
		font-weight: 100;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-26ThIt.otf');
		font-style: italic;
		font-weight: 200;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-25Th.otf');
		font-style: normal;
		font-weight: 200;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-36XLtIt.otf');
		font-style: italic;
		font-weight: 300;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-35XLt.otf');
		font-style: normal;
		font-weight: 300;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-46LtIt.otf');
		font-style: italic;
		font-weight: 400;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-45Lt.otf');
		font-style: normal;
		font-weight: 400;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-56It.otf');
		font-style: italic;
		font-weight: 500;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-55Rg.otf');
		font-style: normal;
		font-weight: 500;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-66MdIt.otf');
		font-style: italic;
		font-weight: 600;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-65Md.otf');
		font-style: normal;
		font-weight: 600;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-76BdIt.otf');
		font-style: italic;
		font-weight: 700;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-75Bd.otf');
		font-style: normal;
		font-weight: 700;
		text-rendering: optimizeLegibility;
	}

	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-96BlkIt.otf');
		font-style: italic;
		font-weight: 900;
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: 'Somniacs';
		src: url('/font/NHaasGroteskDSStd-95Blk.otf');
		font-style: normal;
		font-weight: 900;
		text-rendering: optimizeLegibility;
	}

	html {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}

	body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		font-family: 'Somniacs';
		overflow-x: hidden;
		overflow-y: scroll;
		font-size: 100%;
		letter-spacing: 0.05em;
		color: ${theme.color.foreground};
		background-color: ${theme.color.background};

		&.modal-on {
			overflow-y: hidden;
		}
	}

	.rdrCalendarWrapper {
		flex: 1 1 auto;
	}

	.rdrMonth {
		width: auto;
		padding: 0;
	}

	.rdrDateDisplay {
		background-color: transparent;
	}

	.rdrDateDisplayItem,
	.rdrDateDisplayItemActive {
		box-shadow: none;
		border-radius: 0;
		border-top: 0;
		border-left: 0;
		border-right: 0;

		& input {
			color: ${theme.color.foreground};

			&:focus {
				outline: none;
			}
		}
	}
`;
