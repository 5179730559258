/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

import styled from '../../styled';
import CopyButton from '../form/CopyButton';

import DataValue from './DataValue';
import MetaLabel from './MetaLabel';
import Markdown from './Markdown';

const Wrap = styled('div')`
	margin-top: 0.4em;
	margin-bottom: 0.4em;
`;

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	label: string;
	value: any;
	isFake?: boolean;
	canCopy?: boolean;
	copyValue?: string;
	labelAppendix?: any;
	wrapOverflow?: boolean;
	preserveWhitespace?: boolean;
	markdown?: boolean;
	warning?: string;
}

const LabeledValue: React.FunctionComponent<Props> = ({
	label,
	value,
	isFake,
	canCopy,
	copyValue,
	labelAppendix,
	wrapOverflow,
	preserveWhitespace,
	markdown,
	warning,
	...props
}) => (
	<Wrap {...props}>
		<MetaLabel
			label={label}
			isFake={isFake}
			aside={labelAppendix ? labelAppendix : canCopy === true ? <CopyButton value={copyValue || ''} /> : null}
		/>
		{markdown ? (
			<Markdown>{value as string}</Markdown>
		) : (
			<DataValue value={value} isFake={isFake} wrapOverflow={wrapOverflow} preserveWhitespace={preserveWhitespace} />
		)}
		{warning && (
			<span
				css={(theme) => css`
					color: ${theme.color.warning};
					font-size: ${theme.font.size.label};
				`}
			>
				{warning}
			</span>
		)}
	</Wrap>
);

export default React.memo(LabeledValue);
