import ApolloClient, { InMemoryCache } from 'apollo-boost';

export default new ApolloClient({
	uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : '/graphql',
	cache: new InMemoryCache(),
	credentials: 'include',
	clientState: {
		defaults: {
			modal: false,
			login: {
				__typename: 'Login',
				messages: []
			},
			recover: {
				__typename: 'Recover',
				messages: []
			},
			footer: {
				__typename: 'Footer',
				mode: null,
				createEnabled: false,
				saveEnabled: false,
				deleteEnabled: false,
				previousEnabled: false,
				nextEnabled: false,
				invoiceEnabled: false,
				exportEnabled: false,
				disableReports: false,
				reportsEnabled: false,
				totalRides: 0,
				billedRides: 0
			},
			stats: {
				__typename: 'Stats',
				filters: []
			}
		},
		resolvers: {}
	}
});
