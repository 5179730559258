/** @jsx jsx */
import { ApolloProvider } from '@apollo/react-hooks';
import { jsx } from '@emotion/core';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './container/App';
import registerServiceWorker from './registerServiceWorker';
import client from './services/graphql';
import { GLOBAL_STYLES, theme } from './styled';

ReactDOM.render(
	<ApolloProvider client={client}>
		<Router>
			<ThemeProvider theme={theme}>
				<Global styles={GLOBAL_STYLES} />
				<App />
			</ThemeProvider>
		</Router>
	</ApolloProvider>,
	document.getElementById('root') as HTMLElement
);
registerServiceWorker();
