/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import styled from '../../styled';

const Wrap = styled('div')<TabsetProps>`
	position: sticky;
	top: ${props => props.stickyOffsetTop || props.theme.app.header.heightS};
	z-index: 999;
	background-color: ${props => props.theme.color.background};

	&:after {
		content: '';
		height: 1px;
		background-color: ${props => props.theme.color.grey};
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;

const Inner = styled('div')`
	max-width: ${props => props.theme.dimensions.contentMaxWidth};
	margin-left: auto;
	margin-right: auto;
	display: flex;
	overflow: auto;
	height: ${props => props.theme.tabset.height || 'auto'};
	position: relative;
`;

interface TabsetProps {
	stickyOffsetTop?: number | string;
}

const Tabset: React.FunctionComponent<TabsetProps> = ({
	children,
	stickyOffsetTop
}) => (
	<Wrap stickyOffsetTop={stickyOffsetTop}>
		<Inner>{children}</Inner>
	</Wrap>
);

export default Tabset;
