/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/react-hooks';
import { jsx } from '@emotion/core';
import React from 'react';
import Loadable from 'react-loadable';
import {
	matchPath,
	Redirect,
	Route,
	RouteComponentProps,
	withRouter
} from 'react-router-dom';

import Container from '../components/layout/Container';
import LoadingComponent from '../components/layout/LoadingComponent';
import PageTransition from '../components/layout/PageTransitions';
import Modal from '../components/Modal';
import Tab from '../components/tab/Tab';
import Tabset from '../components/tab/Tabset';
import { GET_AUTH_USER, LOGOUT } from '../graphql/auth';
import { Info } from '../types';

import AppPanel from './Panel';

const CompanyList = Loadable({
	loader: () => import('../views/Company/List'),
	loading: LoadingComponent
});
const CompanySingle = Loadable({
	loader: () => import('../views/Company/Single'),
	loading: LoadingComponent
});
const Login = Loadable({
	loader: () => import('../views/Login'),
	loading: LoadingComponent
});
const MachineList = Loadable({
	loader: () => import('../views/Machine/List'),
	loading: LoadingComponent
});
const MachineSingle = Loadable({
	loader: () => import('../views/Machine/Single'),
	loading: LoadingComponent
});
const Recover = Loadable({
	loader: () => import('../views/Recover'),
	loading: LoadingComponent
});
const Stats = Loadable({
	loader: () => import('../views/Stats'),
	loading: LoadingComponent
});
const UserList = Loadable({
	loader: () => import('../views/User/List'),
	loading: LoadingComponent
});
const UserSingle = Loadable({
	loader: () => import('../views/User/Single'),
	loading: LoadingComponent
});

const App: React.FC<RouteComponentProps> = ({ location, history }) => {
	const [scrolled, setScrolled] = React.useState(false);
	const [updateReady, setUpdateReady] = React.useState(false);

	const path = location.pathname;
	const search = location.search;
	const params = new URLSearchParams(search);
	const isLogin = path.startsWith('/login');
	const isRecover = path.startsWith('/recover');

	const { loading, data } = useQuery<{ me: Info | null }>(GET_AUTH_USER, {
		fetchPolicy: 'network-only'
	});
	const [logout] = useMutation(LOGOUT, {
		refetchQueries: [{ query: GET_AUTH_USER }]
	});

	const onScroll = () => setScrolled(window.pageYOffset > 0);
	const onUpdate = () => {
		navigator.serviceWorker.getRegistrations().then(regs => {
			regs.forEach(reg => {
				if (reg.waiting) {
					setUpdateReady(true);

					const waiting = reg.waiting;
					setTimeout(() => {
						waiting.postMessage({ type: 'SKIP_WAITING' });
					}, 1);
				}
			});
		});
	};

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', onScroll);

			window.addEventListener('updateReady', onUpdate);
			onUpdate();
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('scroll', onScroll);
				window.removeEventListener('updateReady', onUpdate);
			}
		};
	}, []);

	const me = data ? data.me : null;

	return (
		<AppPanel
			scrolled={scrolled}
			secure={!isLogin && !isRecover}
			logout={logout}
			me={me}
		>
			{updateReady && (
				<Modal
					fixed
					title="Update ready"
					text="There is an update available. Please refresh the page."
				/>
			)}
			<Login show={isLogin} />
			<Recover show={isRecover} />
			{!loading ? (
				!me || !me.user ? (
					!isLogin && !isRecover ? (
						<Redirect
							to={
								'/login' +
								(path.length > 1
									? '?redirect=' + encodeURIComponent(path.substr(1) + search)
									: '')
							}
						/>
					) : null
				) : isLogin || params.has('t') ? (
					<Redirect
						to={
							params.has('redirect')
								? decodeURIComponent(params.get('redirect') as string)
								: ''
						}
					/>
				) : (
					me &&
					me.user && (
						<>
							<Tabset>
								<Tab
									label="Machines"
									count={me.machineCount}
									isActive={path.startsWith('/machine')}
									onClick={() => history.push('/machine')}
								/>
								<Tab
									label="Companies"
									count={me.companyCount}
									isActive={path.startsWith('/company')}
									onClick={() => history.push('/company')}
								/>
								<Tab
									label="Users"
									count={me.userCount}
									isActive={path.startsWith('/user')}
									onClick={() => history.push('/user')}
								/>
								<Tab
									label="Stats"
									disabled={false}
									isActive={path.startsWith('/stats')}
									onClick={() => history.push('/stats')}
								/>
							</Tabset>
							<div style={{ position: 'relative' }}>
								<PageTransition in={path === '/machine'}>
									<MachineList me={me} count={me.machineCount} />
								</PageTransition>

								<Route
									path="/machine/:id"
									render={props => (
										<PageTransition
											in={location.pathname.startsWith('/machine/')}
										>
											<MachineSingle me={me} {...props} />
										</PageTransition>
									)}
								/>

								<PageTransition in={path === '/user'}>
									<UserList me={me} count={me.userCount} />
								</PageTransition>

								<Route
									path="/user/:id"
									render={props => (
										<PageTransition in={location.pathname.startsWith('/user/')}>
											<UserSingle me={me} {...props} />
										</PageTransition>
									)}
								/>

								<PageTransition in={path === '/company'}>
									<CompanyList me={me} count={me.companyCount} />
								</PageTransition>

								<Route
									path="/company/:id"
									render={props => (
										<PageTransition
											in={location.pathname.startsWith('/company/')}
										>
											<CompanySingle me={me} {...props} />
										</PageTransition>
									)}
								/>

								<PageTransition in={path.startsWith('/stats')}>
									<Container>
										<Stats />
									</Container>
								</PageTransition>

								{matchPath(location.pathname, {
									path: '/',
									exact: true
								}) && <Redirect to="/stats" />}
							</div>
						</>
					)
				)
			) : null}
		</AppPanel>
	);
};

export default withRouter(App);
