/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ApolloError } from 'apollo-boost';
import format from 'format-number';
import React, { CSSProperties } from 'react';
import { Redirect } from 'react-router-dom';
import { Props as SelectStylesProp } from 'react-select/src/styles';
import { ValueType } from 'react-select/src/types';

import client from '../services/graphql';
import { theme } from '../styled';

const numFormat = format({
	round: 0
});

export type HandleChangeFunction = (
	this: React.Component,
	e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | ValueType<any>,
	meta?: any
) => void;

export function handleChange(
	this: React.Component,
	e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | ValueType<any>,
	meta?: any
): void {
	let name: string;
	let val: any;

	if (meta && meta.name) {
		name = meta.name;
		val = e.value;
	} else if (e.target.type === 'checkbox') {
		// For checkboxes set to true/false
		name = e.target.name;
		val = (e.target as HTMLInputElement).checked;
	} else {
		name = e.target.name;
		val = e.target.value;
	}

	this.setState({
		[name]: val
	});
}

export const ButtonReset = css`
	font: inherit;
	appearance: none;
	border: 0;
	background: transparent;
	border-radius: 0;
	padding: 0;
	margin: 0;
	text-align: inherit;
`;

export const reactSelectStyles: SelectStylesProp = {
	control: (base: CSSProperties, state: any) => ({
		...base,
		background: state.isFocused
			? theme.color.background
			: theme.color.ultraLightgrey,
		borderRadius: 0,
		borderTop: '0',
		borderLeft: '0',
		borderRight: '0',
		transition: 'all .2s ease',
		boxShadow: state.isFocused ? `0 0 1em ${theme.color.primaryLight}` : 'none',
		borderColor: state.isFocused ? theme.color.primary : 'transparent',
		'&:hover': {
			borderColor: theme.color.primary
		}
	}),
	option: (base: CSSProperties, state: any) => ({
		...base,
		borderRadius: 0,
		padding: '1ex',
		fontSize: theme.form.input.fontSize,
		fontWeight: theme.font.weight.thin,
		backgroundColor: state.isSelected
			? theme.color.ultraLightgrey
			: theme.color.background,
		color: theme.color.foreground,
		transition: 'all .4s ease',
		':hover': {
			backgroundColor: theme.color.lightgrey
		}
	}),
	menu: (base: CSSProperties) => ({
		...base,
		borderRadius: 0,
		marginTop: 0,
		marginBottom: 0,
		zIndex: 10
	}),
	menuList: (base: CSSProperties) => ({
		...base,
		borderRadius: 0,
		// kill the white space on first and last option
		padding: 0
	}),
	menuPortal: (base: CSSProperties) => ({
		...base,
		marginTop: 0
	}),
	valueContainer: (base: CSSProperties) => ({
		...base,
		padding: theme.form.input.padding,
		marginBottom: '-1px'
	}),
	singleValue: (base: CSSProperties) => ({
		...base,
		fontWeight: theme.font.weight.thin,
		fontSize: theme.form.input.fontSize
	}),
	dropdownIndicator: (base: CSSProperties) => ({
		...base,
		paddingTop: '0',
		paddingBottom: '0'
	}),
	indicatorSeparator: (base: CSSProperties) => ({
		...base,
		display: 'none'
	})
};

let uuIdCnt = 0;
export const creatUuId = () => ++uuIdCnt;

export const handleGraphQLError = (error: ApolloError) => {
	if (error.message.includes('GraphQL error: Not authorized')) {
		client.writeData({ data: { me: null } });
		const url = encodeURIComponent(window.location.pathname);
		const msg = encodeURIComponent(
			'You have been logged out due to inactivity!'
		);
		return <Redirect to={`/login?redirect=${url}&t=warning&m=${msg}`} />;
	}
	return <p>{error.message}</p>;
};

export const formatActuatorCount = (value: number | undefined) => {
	if (!value) {
		return 'Unknown';
	}

	let text = '';
	if (value >= 1000000) {
		const km = Math.floor(value / 1000000);
		value = value % 1000000;
		text += `${numFormat(km)} km `;
	}
	if (text !== '' || value >= 1000) {
		const m = Math.floor(value / 1000);
		value = value % 1000;
		text += `${numFormat(m)} m `;
	}
	text += `${numFormat(value)} mm`;
	return text;
};
