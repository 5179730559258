import gql from 'graphql-tag';

const baseFields = gql`
	fragment BaseFields on Info {
		user {
			id
			email
			firstName
			lastName
		}
		role {
			id
			name
			canEditMachines
			canEditCompanies
			canEditUsers
		}
		company {
			id
			name
			role {
				id
				isAdmin
			}
		}
		machineCount
		companyCount
		userCount
	}
`;

export const LOGIN = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			...BaseFields
		}
	}
	${baseFields}
`;

export const USE_COMPANY = gql`
	mutation UseCompany($companyId: Int!) {
		useCompany(companyId: $companyId) {
			...BaseFields
		}
	}
	${baseFields}
`;

export const LOGOUT = gql`
	mutation Logout {
		logout
	}
`;

export const GET_AUTH_USER = gql`
	{
		me {
			...BaseFields
		}
	}
	${baseFields}
`;

export const SET_PASSWORD = gql`
	mutation SetPassword($token: String!, $password: String!) {
		setPassword(token: $token, password: $password) {
			...BaseFields
		}
	}
	${baseFields}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($email: String!) {
		resetPassword(email: $email)
	}
`;
