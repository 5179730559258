/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Icon, { IconProps } from './index';

const Download: React.FunctionComponent<IconProps> = (props) => (
	<Icon
		{...props}
		d="m9.999 2-6 6v12a2 2 0 0 0 2 2H18a2.013 2.013 0 0 0 2-2V4a2.013 2.013 0 0 0-2-2zM13 9v4h3l-4 4-4-4h3V9.021Z"
	/>
);

export default Download;
