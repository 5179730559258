/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Icon, { IconProps } from '.';

const Chart: React.FunctionComponent<IconProps> = props => (
	<Icon
		{...props}
		d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99l1.5 1.5z"
	/>
);

export default Chart;
