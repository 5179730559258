import gql from 'graphql-tag';

export const GET_LOGIN = gql`
	{
		login @client {
			messages {
				className
				message
				isActive
			}
		}
	}
`;

export const GET_RECOVER = gql`
	{
		recover @client {
			messages {
				className
				message
				isActive
			}
		}
	}
`;

export const GET_FOOTER = gql`
	{
		footer @client {
			mode
			createEnabled
			saveEnabled
			deleteEnabled
			previousEnabled
			nextEnabled
			invoiceEnabled
			exportEnabled
			disableReports
			reportsEnabled
			totalRides
			billedRides
		}
	}
`;
