import { InterpolationWithTheme } from '@emotion/core';

// Fix for react-select using emotion v9
declare module 'react' {
	interface HTMLAttributes<T> {
		css?: InterpolationWithTheme<any>;
	}
}

export enum FooterMode {
	None,
	List,
	MachineList,
	Single_New,
	Single_Existing,
	Single_Restore,
	Stats
}

export enum EntityType {
	Machine = 'machine',
	User = 'user',
	Company = 'company'
}

export interface Info {
	user: User;
	role: RoleUser;
	company: Company;

	machineCount: number;
	companyCount: number;
	userCount: number;
}

export interface BaseEntity {
	__typename: string;
	id: number;
}

export interface Entity extends BaseEntity {
	createdAt: string;
	updatedAt: string;
	deletedAt?: string;
}

export interface App extends Entity {
	appId: string;
	name: string;
	version: string;
}

export type MuteCategory = 'maintenance' | 'license';

export interface MuteOption {
	indefinite?: boolean;
	date?: string;
}

export interface Machine extends Entity {
	displayName?: string;
	serialNumber: string;
	actuatorCount?: number;
	online: boolean;
	lastStatus?: MachineStatus;
	companyLinks: LinkCompanyMachine[];
	notes: string;
	authToken?: string;
	manualLink?: string;
	muteOptions: Partial<Record<MuteCategory, MuteOption>> | null;
	previous?: Machine;
	next?: Machine;
}

export type LicenseType = 'machine' | 'app';
export type LicenseValidType = 'unlimited' | 'limited';
export const LicenseType = {
	Machine: 'machine',
	App: 'app'
};
export const LicenseValidType = {
	Limited: 'limited',
	Unlimited: 'unlimited'
};

export type LicenseSelector = 'all' | 'allExp' | 'machLic' | string[];
export type LicenseValidity = 'none' | 'valid' | 'expired' | 'expires-1' | 'expires-2' | 'expires-3';
export type LicenseSyncState = 'serverOnly' | 'machineOnly' | 'sync' | 'unsynced' | 'unknown';
export interface License {
	appId: string;
	appName: string;
	appTitle: string;
	licenseType: LicenseType;
	validDate: string | null;
	validType: LicenseValidType;
	validity: LicenseValidity;
	editDate: string;
	clientId?: string;
	errorCode?: string;
}

export interface ExperienceProperty {
	expId: string;
	expName: string;
	expVersion: string;
	installDate: string;
}

export interface SystemProperty<E = string | number> {
	k: string;
	l: string;
	v: E;
}
export type SystemPropertyGroup = SystemProperty<string | number | SystemProperty[]>;

export interface MachineStatus extends Entity {
	machVersion: string;
	ctrlSoftwareVersion: string;
	location?: string;
	macAddr?: string;
	computerName?: string;
	hdSerialNr?: string;
	licenses?: License[];
	installedExperiences?: ExperienceProperty[];
	licenseValidity?: string;
	systemInfo?: SystemPropertyGroup[];
}

export const MaintenanceEventTypes = {
	basicMaintenance: 'Basic Maintenance',
	extendedMaintenance: 'Extended Maintenance',
	commissioning: 'Commissioning',
	prepareForClient: 'Prepare for Client'
} as const;
export type MaintenanceEventType = keyof typeof MaintenanceEventTypes;

export interface MaintenanceEvent extends Entity {
	id: number;
	date: string;
	type: MaintenanceEventType;
	machineId: number | null;
	userId: number | null;
	userName: string | null;
	tasks: string;
	nextTasks: string;
	note: string;
	nextDate: string | null;
}

export const LicenseSaleValidTypes = {
	limited: 'Limited',
	unlimited: 'Unlimited'
} as const;
export type LicenseSaleValidType = keyof typeof LicenseSaleValidTypes;

export const LicenseSaleTypes = {
	subscription: 'Subscription',
	revenueShare: 'Revenue Share',
	oneTimeSales: 'One Time Sales',
	customContent: 'Custom Content'
} as const;
export type LicenseSaleType = keyof typeof LicenseSaleTypes;

export const SubscriptionTypes = {
	annual: 'Annual',
	biannual: 'Biannual',
	quarterly: 'Quarterly',
	monthly: 'Monthly',
	other: 'Other'
} as const;
export type SubscriptionType = keyof typeof SubscriptionTypes;

export const LicenseScopes = {
	basic: 'Basic',
	professional: 'Professional',
	premium: 'Premium',
	other: 'Other'
} as const;
export type LicenseScope = keyof typeof LicenseScopes;

export interface LicenseEntry extends Entity {
	id: number;
	machineId: number | null;
	userId: number | null;
	userName: string | null;
	date: string;
	validType: LicenseSaleValidType;
	validUntil: string | null;
	licenseScope: LicenseScope;
	otherScope: string | null;
	licenseSaleType: LicenseSaleType;
	subscriptionType: SubscriptionType | null;
	revenueSharePricePerRide: number;
	revenueShareFloor: number;
	price: number;
	currency: 'CHF' | 'USD' | 'EUR';
	note: string;
}

export interface User extends Entity {
	email: string;
	firstName: string;
	lastName: string;
	companyCount?: number;
	companyLinks: LinkCompanyUser[];
	notes: string;
	previous?: User;
	next?: User;
}

export interface Company extends Entity {
	name: string;
	addressLine1?: string;
	addressLine2?: string;
	zipCode?: string;
	city?: string;
	state?: string;
	country?: string;
	phone?: string;
	email?: string;
	notes: string;
	machineCount?: number;
	role: RoleCompany;
	machineLinks: LinkCompanyMachine[];
	userLinks: LinkCompanyUser[];
	previous?: Company;
	next?: Company;
}

export interface LinkCompanyUser extends BaseEntity {
	company: Company;
	user: User;
	role: RoleUser;
}

export interface LinkCompanyMachine extends BaseEntity {
	from?: Date;
	to?: Date;
	company: Company;
	machine: Machine;
}

export interface RoleUser extends Entity {
	name: string;
	canEditMachines: boolean;
	canEditCompanies: boolean;
	canEditUsers: boolean;
}

export interface RoleCompany extends Entity {
	name: string;
	isAdmin: boolean;
}

export interface StatsEntry {
	date: string;
	company: Company;
	machine: Machine;
	app: App;
	totalRides: number;
	billedRides: number;
}

export enum Granularity {
	DAY = 'DAY',
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}

export enum DevVisibility {
	HIDE = 'HIDE',
	SHOW = 'SHOW',
	ONLY = 'ONLY'
}

export interface Stats {
	uuid: string;
	from: string;
	to: string;
	granularity: Granularity;
	data: StatsEntry[];
}
