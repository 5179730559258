/** @jsx jsx */
import { useQuery } from '@apollo/react-hooks';
import { css, jsx } from '@emotion/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Button from '../components/form/Button';
import ConfirmButton from '../components/form/ConfirmButton';
import AddIcon from '../components/icons/Add';
import ChartIcon from '../components/icons/Chart';
import CloseIcon from '../components/icons/Close';
import DeleteIcon from '../components/icons/Delete';
import DownloadIcon from '../components/icons/Download';
import NextIcon from '../components/icons/Next';
import PrevIcon from '../components/icons/Prev';
import RestoreIcon from '../components/icons/Restore';
import SaveIcon from '../components/icons/Save';
import Container from '../components/layout/Container';
import LabeledValue from '../components/text/LabeledValue';
import { GET_FOOTER } from '../graphql/local';
import localState from '../services/state';
import styled from '../styled';
import { FooterMode, Info } from '../types';

const Footer = styled('div')`
	background-color: ${(props) => props.theme.app.footer.backgroundColor};
	position: fixed;
	z-index: 9999;
	left: 0;
	bottom: 0;
	width: 100%;
	border-top: 1px solid ${(props) => props.theme.color.grey};
`;

const Inner = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 90%;
`;

const ActionsAside = styled('div')`
	display: flex;
	overflow: auto;
`;

const Actions = styled('div')`
	display: flex;
	overflow: auto;
`;
export interface AppFooterProps {
	me: Info | null;
}

interface AppFooterAndRouteProps extends AppFooterProps, RouteComponentProps {}

const AppFooter: React.FC<AppFooterAndRouteProps> = ({ history, location, me }) => {
	const onCreate = () => {
		history.push(location.pathname.replace('/new', '') + '/new');
	};

	const { error, loading, data } = useQuery<{ footer: any }>(GET_FOOTER);
	const isAdminCompany = me && me.company.role.isAdmin;

	if (error || loading || !data) {
		return null;
	}

	const footer = data.footer;

	return (
		<Footer>
			<Container>
				<Inner>
					{footer.mode === FooterMode.Single_New ? (
						<React.Fragment>
							<ActionsAside />

							<Actions>
								<Button icon={<SaveIcon />} disabled={!footer.saveEnabled} onClick={localState.onSave} title="Save" />

								<Button icon={<CloseIcon />} title="Close" onClick={localState.onClose} />
							</Actions>
						</React.Fragment>
					) : footer.mode === FooterMode.Single_Existing ? (
						<React.Fragment>
							<ActionsAside>
								{localState.onStats && (
									<Button icon={<ChartIcon />} title="View Statistics" onClick={localState.onStats}>
										View Statistics
									</Button>
								)}
							</ActionsAside>

							<Actions>
								<Button icon={<SaveIcon />} disabled={!footer.saveEnabled} onClick={localState.onSave} title="Save" />

								<ConfirmButton
									icon={<DeleteIcon />}
									disabled={!footer.deleteEnabled}
									onClick={localState.onDelete}
									title="Delete"
								/>

								<Button
									icon={<PrevIcon />}
									title="Previous"
									disabled={!footer.previousEnabled}
									onClick={localState.onPrevious}
								/>

								<Button icon={<NextIcon />} title="Next" disabled={!footer.nextEnabled} onClick={localState.onNext} />

								<Button icon={<CloseIcon />} title="Close" onClick={localState.onClose} />
							</Actions>
						</React.Fragment>
					) : footer.mode === FooterMode.Single_Restore ? (
						<React.Fragment>
							<ActionsAside />

							<Actions>
								<Button icon={<RestoreIcon />} onClick={localState.onSave} title="Restore" />

								<Button icon={<CloseIcon />} title="Close" onClick={localState.onClose} />
							</Actions>
						</React.Fragment>
					) : footer.mode === FooterMode.List ? (
						<React.Fragment>
							<ActionsAside>
								<Button icon={<AddIcon />} disabled={!footer.createEnabled} onClick={onCreate}>
									Create New
								</Button>
							</ActionsAside>

							<Actions />
						</React.Fragment>
					) : footer.mode === FooterMode.Stats ? (
						<React.Fragment>
							<ActionsAside>
								<LabeledValue
									label="Total Rides"
									value={footer.totalRides || 0}
									css={css`
										margin-right: 1em;
									`}
								/>
								<LabeledValue label="Billed Rides" value={footer.billedRides || 0} />
							</ActionsAside>

							<Actions>
								<ConfirmButton
									noBorder
									text="Creating an invoice cannot be undone, and will link the rides you have selected to that invoice."
									disabled={!footer.invoiceEnabled}
									onClick={localState.onInvoice}
								>
									Create Invoice
								</ConfirmButton>
								<Button noBorder disabled={!footer.exportEnabled} onClick={localState.onExport}>
									Export to Excel
								</Button>
								{isAdminCompany && (
									<Button noBorder disabled={!footer.exportEnabled} onClick={localState.onExportRides}>
										Export Rides to Excel
									</Button>
								)}
							</Actions>
						</React.Fragment>
					) : footer.mode === FooterMode.MachineList ? (
						<React.Fragment>
							<ActionsAside>
								<Button icon={<AddIcon />} disabled={!footer.createEnabled} onClick={onCreate}>
									Create New
								</Button>
								{footer.reportsEnabled && (
									<>
										<Button
											disabled={footer.disableReports}
											icon={<DownloadIcon />}
											onClick={() => localState.onGenerateReport && localState.onGenerateReport('licenses')}
										>
											Licenses Report
										</Button>
										<Button
											disabled={footer.disableReports}
											icon={<DownloadIcon />}
											onClick={() => localState.onGenerateReport && localState.onGenerateReport('maintenance')}
										>
											Maintenance Report
										</Button>
									</>
								)}
							</ActionsAside>

							<Actions />
						</React.Fragment>
					) : null}
				</Inner>
			</Container>
		</Footer>
	);
};

export default withRouter(AppFooter);
