/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { ComponentPropsWithoutRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Theme } from '../../styled';

const Markdown: React.FunctionComponent<ComponentPropsWithoutRef<'div'> & { children: string }> = ({
	children,
	...props
}) => (
	<ReactMarkdown
		css={(theme: Theme) => css`
			font-size: ${theme.form.input.fontSize};
			font-weight: ${theme.font.weight.thin};
			a {
				color: inherit;
			}
			* {
				margin-top: 0;
				margin-bottom: 0;
			}
		`}
		components={{
			a: (props) => <a target="_blank" rel="noreferrer noopener" {...props} />
		}}
		{...props}
	>
		{children}
	</ReactMarkdown>
);

export default React.memo(Markdown);
