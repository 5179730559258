class LocalState {
	public onSave: () => void;
	public onDelete: () => void;
	public onPrevious: () => void;
	public onNext: () => void;
	public onClose: () => void;
	public onStats?: () => void;
	public onInvoice?: () => void;
	public onExport?: () => void;
	public onExportRides?: () => void;
	public onGenerateReport?: (type: string) => void;
	public onModalOpen: () => void;
	public onModalClose: () => void;

	public constructor() {
		this.onSave = () => {};
		this.onDelete = () => {};
		this.onPrevious = () => {};
		this.onNext = () => {};
		this.onClose = () => {};
		this.onStats = () => {};
		this.onInvoice = () => {};
		this.onExport = () => {};
		this.onExportRides = () => {};
		this.onGenerateReport = () => {};
		this.onModalOpen = () => {};
		this.onModalClose = () => {};
	}
}

export default new LocalState();
