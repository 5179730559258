/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import AriaModal from 'react-aria-modal';

import localState from '../services/state';
import { Theme } from '../styled';

import Button from './form/Button';

interface Props {
	theme: Theme;
	hideOk?: boolean;
	cancel?: boolean;
	fixed?: boolean;
	title?: string;
	text?: string;
	onClickOK?: () => void;
	onClickCancel?: () => void;
}

const AriaModalComp: React.FunctionComponent<Props> = ({
	theme,
	hideOk,
	cancel,
	fixed,
	title,
	text,
	onClickOK,
	onClickCancel
}) => {
	const activateModal = () => localState.onModalOpen();
	const deactivateModal = () => localState.onModalClose();

	const handleExit = () => {
		deactivateModal();
		if (onClickCancel) {
			onClickCancel();
		}
	};
	const handleClickCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		deactivateModal();
		if (onClickCancel) {
			onClickCancel();
		}
	};
	const handleClickOK = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		// Handle confirmed action
		deactivateModal();
		if (onClickOK) {
			onClickOK();
		}
	};

	return (
		<ClassNames>
			{({ css: _css }) => (
				<AriaModal
					titleText="Confirm"
					initialFocus="#modal-confirm-button-ok"
					onEnter={activateModal}
					onExit={() => !fixed && handleExit()}
					scrollDisabled={false}
					focusDialog={fixed}
					underlayClass={_css`
					position: fixed;
					z-index: 9999;
					left: 0;
					top: 0;
					width: 100%;
					min-height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: rgba(0, 0, 0, 0.7);
				`}
					dialogClass={_css`
					border: 1px solid ${theme.color.primary};
					background-color: ${theme.color.background};

					& .modal-header {
						padding: 1ex;
						background-color: ${theme.color.primary};
						color: ${theme.color.primaryContrast};

						& span {
							font-weight: 900;
						}
					}

					& .modal-body {
						padding: 1ex;
					}

					& .modal-footer {
						display: flex;
						flex-flow: row-reverse nowrap;
						justify-content: space-between;
					}
				`}
					includeDefaultStyles={false}
				>
					<div>
						<div className="modal-header">
							<span>{title || 'Are you sure?'}</span>
						</div>
						{text && <div className="modal-body">{text}</div>}
						{!fixed && (
							<footer className="modal-footer">
								{!hideOk && (
									<Button id="modal-confirm-button-ok" onClick={handleClickOK}>
										OK
									</Button>
								)}
								{cancel && (
									<Button
										id="modal-confirm-button-cancel"
										onClick={handleClickCancel}
									>
										Cancel
									</Button>
								)}
							</footer>
						)}
					</div>
				</AriaModal>
			)}
		</ClassNames>
	);
};

export default withTheme(AriaModalComp);
