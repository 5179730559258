/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { MouseEventHandler } from 'react';

import { Theme, withTheme } from '../../styled';
import ClipboardIcon from '../icons/Assignment';
import ClipboardDoneIcon from '../icons/AssignmentTurned';

const copyIconClass = (color: string, copyDone: boolean) => css`
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	transition: fill 0.2s ease;
	fill: ${copyDone ? color : 'inherit'};
	padding: 0.2em;
	margin: -0.2em;
`;

const copyLabelClass = (theme: Theme, show: boolean) => css`
	position: absolute;
	right: 1.35em;
	background: ${theme.color.background};
	color: ${theme.color.foreground};
	padding: 0.2em;
	top: 0;
	transition: opacity 0.4s ease 0.2s;
	opacity: ${show ? 1 : 0};
`;

const copyInputClass = css`
	appearance: none;
	border: 0;
	padding: 0;
	margin: 0;
	opacity: 0;
	width: 1;

	&:focus: {
		outline: none;
	}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	value: string;
	theme: Theme;
}

export const CopyButton: React.FunctionComponent<Props> = ({ value, theme }) => {
	const [showCopyLabel, setShowCopyLabel] = React.useState(false);
	const [copyDone, setCopyDone] = React.useState(false);
	const copyInputRef = React.useRef<HTMLInputElement>(null);

	const onMouseOver = () => setShowCopyLabel(true);
	const onMouseOut = () => setShowCopyLabel(false);

	const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
		e.stopPropagation();
		if (copyInputRef.current) {
			copyInputRef.current.select();
			document.execCommand('copy');
			setCopyDone(true);
			setTimeout(() => setCopyDone(false), 800);
		}
	};

	return (
		<div
			css={copyIconClass(theme.color.primary, copyDone)}
			role="button"
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			onClick={onClick}
		>
			<input css={copyInputClass} type="text" value={value} ref={copyInputRef} readOnly />
			<div css={copyLabelClass(theme, showCopyLabel)}>Click to copy</div>
			{copyDone ? (
				<ClipboardDoneIcon
					css={css`
						width: 1em;
						height: 1em;
					`}
				/>
			) : (
				<ClipboardIcon
					css={css`
						width: 1em;
						height: 1em;
					`}
				/>
			)}
		</div>
	);
};

export default withTheme(CopyButton);
