import React from 'react';

import localState from '../services/state';
import styled, { withTheme } from '../styled';

import Footer from './Footer';
import Header, { AppHeaderProps } from './Header';

const Panel = styled('div')`
	box-sizing: border-box;
	padding-bottom: ${props => props.theme.app.footer.height};
	min-height: 100vh;
	position: relative;
	background-color: ${props => props.theme.color.background};
`;

// tslint:disable-next-line: no-empty-interface
export interface AppPanelProps extends AppHeaderProps {}

class AppPanel extends React.Component<AppPanelProps, {}> {
	public constructor(props: AppPanelProps) {
		super(props);
	}

	public componentDidMount() {
		localState.onModalOpen = () => {
			document.body.classList.add('modal-on');
		};
		localState.onModalClose = () => {
			document.body.classList.remove('modal-on');
		};
	}

	public componentWillUnmount() {
		document.body.classList.remove('modal-on');
	}

	public render() {
		const { scrolled, secure, me, logout, children, ...props } = this.props;

		return (
			<Panel {...props}>
				<Header scrolled={scrolled} secure={secure} me={me} logout={logout} />
				<div>{children}</div>
				{secure && <Footer me={me} />}
			</Panel>
		);
	}
}

export default withTheme(AppPanel);
