/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Icon, { IconProps } from './index';

const Prev: React.FunctionComponent<IconProps> = props => (
	<Icon
		{...props}
		d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z"
	/>
);

export default Prev;
