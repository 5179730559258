/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import styled from '../../styled';

const Label = styled('label')<MetaLabelStyleProps>`
	display: block;
	cursor: inherit;
	font-weight: ${props => props.theme.font.styles.meta.fontWeight};
	text-transform: ${props => props.theme.font.styles.meta.textTransform};
	font-size: ${props => props.theme.font.styles.meta.fontSize};
	letter-spacing: ${props => props.theme.font.styles.meta.letterSpacing};
	transition: all 0.4s ease;
	${props =>
		props.isFake
			? `
			filter: blur(.2em);`
			: null}
`;

const Aside = styled('div')`
	float: right;
`;

interface MetaLabelStyleProps {
	isFake?: boolean;
}

export interface MetaLabelProps
	extends MetaLabelStyleProps,
		React.LabelHTMLAttributes<HTMLLabelElement> {
	label: string | React.ReactNode;
	htmlFor?: string;
	aside?: React.ReactNode;
}

const MetaLabel: React.FunctionComponent<MetaLabelProps> = ({
	label,
	aside,
	...props
}) => (
	<Label {...props}>
		{aside && <Aside>{aside}</Aside>}
		{label}
	</Label>
);

export default React.memo(MetaLabel);
