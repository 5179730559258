/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Icon, { IconProps } from './index';

const Next: React.FunctionComponent<IconProps> = props => (
	<Icon
		{...props}
		d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"
	/>
);

export default Next;
