/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

import { Theme } from '../../styled';

const Container: React.FunctionComponent = ({ children, ...props }) => (
	<div
		css={(theme: Theme) => css`
			max-width: ${theme.dimensions.contentMaxWidth};
			margin-left: auto;
			margin-right: auto;
			padding-left: ${theme.dimensions.sidePadding};
			padding-right: ${theme.dimensions.sidePadding};
			box-sizing: border-box;
		`}
		{...props}
	>
		{children}
	</div>
);

export default Container;
